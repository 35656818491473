<template>
  <div>
    <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
      <CardLoader v-if="isLoading" :with-text="false"/>
      <!--begin::Body-->
      <div class="card-body my-4">
        <div class="d-flex">
          <span class="card-title font-weight-bolder text-dark-75 font-size-h6 mb-4 d-block">
            {{ $t('title.contracts') }}
          </span>
          <span v-if="overdueCount" class="label label-danger ml-2">{{ overdueCount }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column">
            <span class="font-weight-bolder font-size-h2 mr-2" :class="computedRemainValue > 0 ? 'text-success' : 'text-dark-75'">
              {{ `${computedRemainValue} ${computedCurrencySymbol}` }}
            </span>
            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.remains_to_pay') }}</span>
          </div>
<!--          <div class="d-flex flex-column">-->
<!--            <span class="text-dark-75 font-weight-bolder font-size-h2 text-right">600 $</span>-->
<!--            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.next_payment') }}</span>-->
<!--          </div>-->
        </div>
        <div class="progress progress-xs mt-7 bg-info-o-60">
          <div class="progress-bar bg-info"
               role="progressbar"
               :style="`width: ${computedPercentage}%`"
               aria-valuenow="50"
               aria-valuemin="0"
               aria-valuemax="100"
          ></div>
        </div>
        <span class="form-text text-muted font-size-xs">
          {{ `~ ${computedPercentage}% ${$t('label.until_full_repayment')}` }}
        </span>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  data: () => ({
    isLoading: true
  }),
  beforeCreate() {
    this.$store.dispatch('contractsStore/GET_SUMMARY');
  },
  computed: {
    ...mapGetters({
      overdueCount: 'contractsStore/OVERDUE_COUNT',
      summary: 'contractsStore/SUMMARY',
    }),
    computedRemainValue() {
      return this.summary ? this.summary.remain.value : 0;
    },
    computedCurrencySymbol() {
      return this.summary?.remain.currency_symbol || '$';
    },
    computedPercentage() {
      return this.summary?.paid_percentage || 0;
    }
  },
  watch: {
    summary: {
      immediate: true,
      handler(data) {
        if (data) {
          this.isLoading = false;
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$store.commit('contractsStore/CLEAR_DATA');
  }
}
</script>
