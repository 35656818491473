<template>
  <div>
    <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
      <CardLoader v-if="isLoading" :with-text="false"/>
      <!--begin::ody-->
      <div class="card-body my-4">
        <span class="card-title font-weight-bolder text-dark-75 font-size-h6 mb-4 d-block">
          {{ $t('title.utilities') }}
        </span>
        <div class="mt-7 d-flex justify-content-between">
          <div class="d-flex flex-column">
            <span class="font-weight-bolder font-size-h2 mr-2"
                  :class="computedElectricityTotalValue >= 0 ? (computedElectricityTotalValue == 0 ? 'text-dark-75' : 'text-danger') : 'text-success'">
              {{ `${computedElectricityTotalValue} ${computedElectricityTotalCurrencySymbol}` }}
            </span>
            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.electricity') }}</span>
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bolder font-size-h2 text-right"
                  :class="computedWaterTotalValue >= 0 ? (computedWaterTotalValue == 0 ? 'text-dark-75' : 'text-danger') : 'text-success'">
              {{ `${computedWaterTotalValue} ${computedWaterTotalCurrencySymbol}` }}
            </span>
            <span class="font-weight-bold text-muted text-right font-size-sm">{{ $t('label.water') }}</span>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  data: () => ({
    isLoading: true
  }),
  beforeCreate() {
    this.$store.dispatch('apartmentElectricityStore/GET_ELECTRICITY_TOTAL');
    this.$store.dispatch('apartmentWaterStore/GET_WATER_TOTAL');
  },
  computed: {
    ...mapGetters({
      electricityTotal: 'apartmentElectricityStore/ELECTRICITY_TOTAL',
      waterTotal: 'apartmentWaterStore/WATER_TOTAL',
    }),

    computedElectricityTotalValue() {
      return this.electricityTotal?.value || 0;
    },
    computedElectricityTotalCurrencySymbol() {
      return this.electricityTotal?.currency_symbol || '';
    },
    computedWaterTotalValue() {
      return this.waterTotal?.value || 0;
    },
    computedWaterTotalCurrencySymbol() {
      return this.waterTotal?.currency_symbol || '';
    },
  },
  watch: {
    electricityTotal: {
      immediate: true,
      handler(data) {
        if (data) {
          this.isLoading = false;
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$store.commit('apartmentElectricityStore/CLEAR_DATA');
  }
}
</script>
