<template>
  <div>
    <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
      <CardLoader v-if="isLoading" :with-text="false"/>
      <!--begin::Body-->
      <div class="card-body my-4">
<!--        text-hover-state-dark-->
        <span class="card-title font-weight-bolder text-dark-75 font-size-h6 mb-4 d-block">
          {{ $t('title.electronic_keys') }}
        </span>
        <div class="mt-7 d-flex justify-content-between">
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{{ computedActiveKeysCount }}</span>
            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.active_keys') }}</span>
          </div>
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-h2 text-right">{{ computedPausedKeysCount }}</span>
            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.paused_keys') }}</span>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  data: () => ({
    isLoading: true
  }),
  beforeCreate() {
    this.$store.dispatch('eKeysStore/GET_KEYS_COUNT');
  },
  computed: {
    ...mapGetters({
      keysCount: 'eKeysStore/KEYS_COUNT'
    }),
    computedActiveKeysCount() {
      return this.keysCount?.active || 0;
    },
    computedPausedKeysCount() {
      return this.keysCount?.pause || 0;
    },
  },
  watch: {
    keysCount: {
      immediate: true,
      handler(data) {
        if (data) {
          this.isLoading = false;
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$store.commit('eKeysStore/CLEAR_DATA');
  }
}
</script>
