<template>
  <div>
    <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
      <CardLoader v-if="isLoading" :with-text="false"/>
      <!--begin::Body-->
      <div class="card-body my-3">
        <router-link
            to="bookings"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a href="/bookings"
             class="card-title font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block"
             @click="navigate"
          >
            {{ $t('title.bookings_and_visits') }}
          </a>
        </router-link>
        <div class="mt-7 d-flex justify-content-between">
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-h2 mr-2">{{ computedNewCount }}</span>
            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.bookings_new') }}</span>
          </div>
          <div class="d-flex flex-column">
            <span class="text-dark-75 font-weight-bolder font-size-h2 text-right">{{ computedCurrentCount }}</span>
            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.bookings_current') }}</span>
          </div>
        </div>
      </div>
      <!--end:: Body-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  data: () => ({
    isLoading: true
  }),
  beforeCreate() {
    this.$store.dispatch('bookingsStore/GET_NEW_COUNT');
    this.$store.dispatch('bookingsStore/GET_CURRENT_COUNT');
  },
  computed: {
    ...mapGetters({
      newCount: 'bookingsStore/NEW_COUNT',
      currentCount: 'bookingsStore/CURRENT_COUNT'
    }),
    computedNewCount() {
      return this.newCount || 0;
    },
    computedCurrentCount() {
      return this.currentCount || 0;
    },
  },
  watch: {
    newCount: {
      immediate: true,
      handler(value) {
        if (Number.isInteger(value)) {
          this.isLoading = false;
        }
      },
      deep: true
    },
    currentCount: {
      immediate: true,
      handler(value) {
        if (Number.isInteger(value)) {
          this.isLoading = false;
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$store.commit('bookingsStore/CLEAR_DATA');
  }
}
</script>