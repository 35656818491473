<template>
  <div class="dashboard-page">
    <div class="row">
      <ContractsCard class="col-md-4 dashboard-card__wrapper"/>
      <BookingsCard class="col-md-4 dashboard-card__wrapper"/>
      <ElectronicKeysCard class="col-md-4 dashboard-card__wrapper"/>
    </div>
    <div class="row">
      <ServicesCard class="col-md-4 dashboard-card__wrapper"/>
<!--      <RecommendationsCard class="col-md-4 dashboard-card__wrapper"/>-->
<!--      <WalletInfo class="col-md-4 dashboard-card__wrapper" with-add-recommendation-btn/>-->
      <UtilitiesCard class="col-md-4 dashboard-card__wrapper"/>
    </div>
  </div>
</template>

<script>
import BookingsCard from '@/components/dashboard/BookingsCard';
import ContractsCard from '@/components/dashboard/ContractsCard';
import ElectronicKeysCard from '@/components/dashboard/ElectronicKeysCard';
import WalletInfo from '@/views/loyalty/components/WalletInfo';
// import RecommendationsCard from '@/components/dashboard/RecommendationsCard';
import ServicesCard from '@/components/dashboard/ServicesCard';
import UtilitiesCard from '@/components/dashboard/UtilitiesCard';

export default {
  components: {
    BookingsCard,
    ContractsCard,
    ElectronicKeysCard,
    // WalletInfo,
    // RecommendationsCard,
    ServicesCard,
    UtilitiesCard
  },
}
</script>

<style lang="scss" scoped>
.dashboard-card__wrapper::v-deep {
  min-height: 200px !important;

  & .card-body {
    padding: 10px 20px 15px 20px !important;
  }
}
</style>