<template>
  <div>
    <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
      <CardLoader v-if="isLoading" :with-text="false"/>
      <!--begin::ody-->
      <div class="card-body my-4">
        <router-link
            to="apartments"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
        <a href="/apartments"
           class="card-title font-weight-bolder text-info text-hover-state-dark font-size-h6 mb-4 d-block"
           @click="navigate">
          {{ $t('title.apartment_service') }}
        </a>
        <div class="mt-7 d-flex justify-content-between">
          <div class="d-flex flex-column">
            <span class="font-weight-bolder font-size-h2 mr-2"
                  :class="computedBalanceValue >= 0 ? (computedBalanceValue == 0 ? 'text-dark-75' : 'text-danger') : 'text-success'"
            >
              {{ `${computedBalanceValue} ${computedBalanceCurrencySymbol}` }}
            </span>
            <span class="font-weight-bold text-muted font-size-sm">{{ $t('label.balance') }}</span>
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bolder font-size-h2 text-right"
                  :class="computedDebtValue > 0 ? 'text-danger' : 'text-dark-75'"
            >
              {{ `${computedDebtValue} ${computedDebtCurrencySymbol}` }}
            </span>
            <span class="font-weight-bold text-muted font-size-sm text-right">{{ $t('label.debt') }}</span>
          </div>
        </div>
        </router-link>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CardLoader from '@/components/elements/loaders/CardLoader';

export default {
  components: {
    CardLoader
  },
  data: () => ({
    isLoading: true
  }),
  mounted() {
    this.$store.dispatch('apartmentServicesStore/GET_SERVICES_TOTAL');
  },
  computed: {
    ...mapGetters({
      servicesTotal: 'apartmentServicesStore/SERVICES_TOTAL'
    }),

    computedBalanceValue() {
      return this.servicesTotal?.balance?.value || 0;
    },
    computedBalanceCurrencySymbol() {
      return this.servicesTotal?.balance?.currency_symbol || '';
    },
    computedDebtValue() {
      return this.servicesTotal?.debt?.value || 0;
    },
    computedDebtCurrencySymbol() {
      return this.servicesTotal?.debt?.currency_symbol || '';
    },
  },
  watch: {
    servicesTotal: {
      immediate: true,
      handler(data) {
        if (data) {
          this.isLoading = false;
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('apartmentServicesStore/CLEAR_DATA');
  }
}
</script>
